import { useState } from 'react';
import { GameBoard } from './game/GameBoard';
import { Footer } from './footer/Footer';
import { GameOverModal } from './game/GameOverModal';
import { useGameLogic } from './hooks/GameLogic';
import { handleShare } from './share/handleShare';
import { getRandomColorCombo } from './data/colors';

export default function App() {
  const [colors] = useState(getRandomColorCombo());
  const {
    score,
    highScore,
    currentPair,
    showGameOver,
    gameState,
    handleGuess,
    handlePlayAgain,
    getWinnerLoserStatus
  } = useGameLogic();

  return (
    <div className="h-screen flex flex-col" style={{ backgroundColor: colors.background }}>
      <main className="flex-1 flex items-center justify-center min-h-0">
        <div className="w-full max-w-4xl mx-auto px-4">
          <GameBoard
            score={score}
            highScore={highScore}
            currentPair={currentPair}
            gameState={gameState}
            colors={colors}
            handleGuess={handleGuess}
            getWinnerLoserStatus={getWinnerLoserStatus}
          />
        </div>
      </main>
      <Footer bgColor={colors.footer} colors={colors} />
      
      {showGameOver && (
        <GameOverModal
          score={score}
          highScore={highScore}
          onShare={() => handleShare(score, highScore)}
          onPlayAgain={handlePlayAgain}
        />
      )}
    </div>
  );
}