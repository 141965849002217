// Footer.jsx
import React, { useState } from 'react';
import { ColorModal } from './components/ColorModal';
import { KofiButton } from './components/KofiButton';
import { EmojiDisplay } from './components/emoji/EmojiDisplay';
import { emojis } from './components/emoji/RollingEmoji';

const getRandomEmojis = (count) => {
    return Array.from({ length: count }, () => 
        emojis[Math.floor(Math.random() * emojis.length)]
    );
};

export function Footer({ bgColor, colors }) {
    const textColor = getBrightness(bgColor) > 128 ? '#1a1a1a' : '#ffffff';
    const [finalEmojis] = useState(getRandomEmojis(3));

    return (
        <footer 
            style={{ backgroundColor: bgColor }} 
            className="w-full shadow-[0_-1px_3px_rgba(0,0,0,0.1)]"
        >
            <style>
                {`
                    @keyframes slide-up {
                        0% { transform: translateY(-100%); }
                        100% { transform: translateY(100%); }
                    }
                `}
            </style>
            <div className="max-w-7xl mx-auto px-4 py-6">
                <div className="flex flex-col md:flex-row justify-between items-center gap-4">
                    <p style={{ color: textColor }} className="text-sm">
                        Made with <EmojiDisplay finalEmojis={finalEmojis} /> by Harry
                    </p>
                    
                    <div className="flex items-center gap-4">
                        <ColorModal colors={colors} />
                        <KofiButton />
                    </div>
                </div>
            </div>
        </footer>
    );
}

function getBrightness(hexColor) {
    const hex = hexColor.replace('#', '');
    const r = parseInt(hex.substr(0, 2), 16);
    const g = parseInt(hex.substr(2, 2), 16);
    const b = parseInt(hex.substr(4, 2), 16);
    return (r * 299 + g * 587 + b * 114) / 1000;
    }