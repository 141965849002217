export const COLOR_COMBINATIONS = [
    {
      background: '#F0F4F8',  // Light Blue-Grey
      card: '#89ABE3',       // Sky Blue
      footer: '#5A7BB5'      // Darker Blue
    },
    {
      background: '#FFF5F5',  // Soft Pink Background
      card: '#FFB88C',       // Peach
      footer: '#DE6262'      // Coral Red
    },
    {
      background: '#F0FFF4',  // Mint White
      card: '#96E6A1',       // Mint Green
      footer: '#48B1BF'      // Turquoise
    },
    {
      background: '#FFFAF0',  // Warm White
      card: '#FDA085',       // Soft Orange
      footer: '#F6D365'      // Yellow
    },
    {
      background: '#F3F0FF',  // Lavender White
      card: '#8E7CC3',       // Lavender
      footer: '#764BA2'      // Purple
    },
    {
      background: '#F5FFF9',  // Cool White
      card: '#A8E6CF',       // Mint
      footer: '#88D4B0'      // Darker Mint
    },
    {
      background: '#FFF0F3',  // Pink White
      card: '#FF9A9E',       // Light Red
      footer: '#E38386'      // Darker Pink
    },
    {
      background: '#0A1930',  // Dark Navy
      card: '#2C5364',       // Medium Blue
      footer: '#193446'      // Dark Blue
    },
    {
      background: '#FFF8E7',  // Cream
      card: '#F7971E',       // Orange
      footer: '#FFD200'      // Yellow
    },
    {
      background: '#E8F4F8',  // Light Blue
      card: '#56CCF2',       // Sky Blue
      footer: '#2F80ED'      // Royal Blue
    },
    {
      background: '#EFFAF6',  // Light Mint
      card: '#11998E',       // Teal
      footer: '#38EF7D'      // Bright Green
    },
    {
      background: '#FFF0E5',  // Peach White
      card: '#FF8C42',       // Orange
      footer: '#FF5733'      // Darker Orange
    },
    {
        background: '#F6F7FF',  // Ice White
        card: '#70A1FF',       // Fresh Blue
        footer: '#1E90FF'      // Dodger Blue
      },
      {
        background: '#121212',  // Dark Mode Black
        card: '#BB86FC',       // Material Purple
        footer: '#3700B3'      // Deep Purple
      },
      {
        background: '#FFFBF5',  // Vanilla Cream
        card: '#F4C430',       // Saffron
        footer: '#E6B800'      // Golden Yellow
      },
      {
        background: '#F0EBFF',  // Lilac White
        card: '#A78BFA',       // Light Purple
        footer: '#7C3AED'      // Deep Purple
      },
      {
        background: '#EBFFF0',  // Honeydew
        card: '#34D399',       // Emerald
        footer: '#059669'      // Deep Emerald
      },
      {
        background: '#FFF1F2',  // Rose White
        card: '#FB7185',       // Rose
        footer: '#E11D48'      // Deep Rose
      },
      {
        background: '#EEF2FF',  // Indigo White
        card: '#818CF8',       // Bright Indigo
        footer: '#4F46E5'      // Deep Indigo
      },
      {
        background: '#FEF3C7',  // Amber Light
        card: '#F59E0B',       // Amber
        footer: '#B45309'      // Dark Amber
      },
      {
        background: '#172554',  // Deep Blue
        card: '#3B82F6',       // Bright Blue
        footer: '#1D4ED8'      // Royal Blue
      },
      {
        background: '#FFE4E1',  // Misty Rose
        card: '#FF69B4',       // Hot Pink
        footer: '#FF1493'      // Deep Pink
      }
  ];
  
  export function getRandomColorCombo() {
    return COLOR_COMBINATIONS[Math.floor(Math.random() * COLOR_COMBINATIONS.length)];
  }