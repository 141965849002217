// components/GameBoard.js
import { GameCard } from './components/GameCard';
import { ScoreBubble } from './components/ScoreBubble';

export const GameBoard = ({ 
  score, 
  highScore, 
  currentPair, 
  gameState, 
  colors,
  handleGuess,
  getWinnerLoserStatus 
}) => {
  return (
    <div className="relative grid md:grid-cols-2 gap-3 sm:gap-4">
      <ScoreBubble 
        score={score} 
        highScore={highScore} 
        colour_one={colors.card}
        colour_two={colors.footer}
      />
      {currentPair.map((item, index) => (
        <GameCard
          key={`${item.id}-${item.isNew ? 'new' : 'kept'}`}
          item={item}
          index={index}
          showResult={gameState.showResult}
          {...getWinnerLoserStatus(item)}
          onSelect={!gameState.isGameOver ? handleGuess : undefined}
          showYear={index === 0 || gameState.showResult}
          isAnimatingOut={gameState.isAnimatingOut && 
            (!gameState.lastGuessCorrect || !item.isNew)}
          isAnimatingIn={gameState.isAnimatingIn && item.isNew}
          bgColor={colors.card}
        />
      ))}
    </div>
  );
};