import { useState, useEffect } from 'react';
import confetti from 'canvas-confetti';
import { SupabaseRepository } from '../repositories/SupabaseRepository';
import { sampleData } from '../data/data';

const BATCH_SIZE = 50; // Fetch 20 items at once

export const useGameLogic = () => {
  const [score, setScore] = useState(0);
  const [highScore, setHighScore] = useState(() => parseInt(localStorage.getItem('highScore')) || 0);
  const [currentPair, setCurrentPair] = useState([]);
  const [showGameOver, setShowGameOver] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isUsingSampleData, setIsUsingSampleData] = useState(false);
  const [itemPool, setItemPool] = useState([]);
  const [gameState, setGameState] = useState({
    showResult: false,
    selectedItem: null,
    isAnimatingOut: false,
    isAnimatingIn: false,
    lastGuessCorrect: false,
    isGameOver: false
  });

  useEffect(() => {
    const initGame = async () => {
      try {
        setIsLoading(true);
        // Fetch a batch of items
        const items = await SupabaseRepository.getRandomItems(BATCH_SIZE);
        
        if (!items || items.length < 2) {
          throw new Error('Failed to load initial items');
        }
        
        setIsUsingSampleData(false);
        // Store first two items as current pair
        setCurrentPair([
          { ...items[0], isNew: true },
          { ...items[1], isNew: true }
        ]);
        // Store remaining items in pool
        setItemPool(items.slice(2));
      } catch (err) {
        console.error('Failed to initialize game:', err);
        const fallbackPair = [...sampleData]
          .sort(() => Math.random() - 0.5)
          .slice(0, 2)
          .map(item => ({ ...item, isNew: true }));
        setCurrentPair(fallbackPair);
        setIsUsingSampleData(true);
      } finally {
        setIsLoading(false);
      }
    };
    initGame();
  }, []);

  useEffect(() => {
    if (score > highScore) {
      setHighScore(score);
      localStorage.setItem('highScore', score.toString());
    }
  }, [score, highScore]);

  const getNextItem = async (excludeId) => {
    // If we have items in the pool, use those
    if (itemPool.length > 0) {
      const nextItem = itemPool[0];
      setItemPool(prev => prev.slice(1));
      return nextItem;
    }

    // If pool is empty, fetch new batch
    try {
      const newItems = await SupabaseRepository.getRandomItems(BATCH_SIZE, excludeId);
      if (newItems && newItems.length > 0) {
        const [nextItem, ...remainingItems] = newItems;
        setItemPool(remainingItems);
        return nextItem;
      }
    } catch (err) {
      console.error('Failed to fetch new items:', err);
    }

    // Fallback to sample data if fetch fails
    return null;
  };

  const handleGuess = async (selectedItem) => {
    const otherItem = currentPair.find(item => item !== selectedItem);
    const isCorrect = selectedItem.year <= otherItem.year;

    setGameState(prev => ({
      ...prev,
      showResult: true,
      selectedItem,
      lastGuessCorrect: isCorrect
    }));

    if (isCorrect) {
      setScore(prev => prev + 1);
      confetti({
        particleCount: 100,
        spread: 70,
        origin: { y: 0.6 }
      });

      const animationSequence = async () => {
        const keepItem = selectedItem.isNew ? selectedItem : otherItem;
        
        try {
          await new Promise(resolve => setTimeout(resolve, 1500));
          setGameState(prev => ({ ...prev, isAnimatingOut: true }));

          await new Promise(resolve => setTimeout(resolve, 500));
          
          let newItem;

          if (isUsingSampleData) {
            const availableItems = sampleData.filter(item => item.id !== keepItem.id);
            newItem = availableItems[Math.floor(Math.random() * availableItems.length)];
          } else {
            newItem = await getNextItem(keepItem.id);
            if (!newItem) {
              throw new Error('Failed to load next item');
            }
          }

          setCurrentPair([
            { ...keepItem, isNew: false },
            { ...newItem, isNew: true }
          ]);

          setGameState(prev => ({
            ...prev,
            showResult: false,
            selectedItem: null,
            isAnimatingOut: false,
            isAnimatingIn: true,
            lastGuessCorrect: false
          }));

          await new Promise(resolve => setTimeout(resolve, 500));
          setGameState(prev => ({ ...prev, isAnimatingIn: false }));
        } catch (err) {
          console.error('Failed to load next item:', err);
          if (!isUsingSampleData) {
            setIsUsingSampleData(true);
            const availableItems = sampleData.filter(item => item.id !== keepItem.id);
            const fallbackItem = availableItems[Math.floor(Math.random() * availableItems.length)];
            setCurrentPair([
              { ...keepItem, isNew: false },
              { ...fallbackItem, isNew: true }
            ]);
          } else {
            setGameState(prev => ({ ...prev, isGameOver: true }));
          }
        }
      };

      animationSequence();
    } else {
      setGameState(prev => ({
        ...prev,
        isGameOver: true
      }));

      setTimeout(() => {
        setShowGameOver(true);
      }, 1500);
    }
  };

  const handlePlayAgain = async () => {
    try {
      setIsLoading(true);
      setShowGameOver(false);
      setScore(0);
      
      let initialItems;
      if (isUsingSampleData) {
        initialItems = [...sampleData]
          .sort(() => Math.random() - 0.5)
          .slice(0, BATCH_SIZE);
      } else {
        initialItems = await SupabaseRepository.getRandomItems(BATCH_SIZE);
        if (!initialItems || initialItems.length < 2) {
          throw new Error('Failed to load initial items');
        }
      }
      
      setCurrentPair([
        { ...initialItems[0], isNew: true },
        { ...initialItems[1], isNew: true }
      ]);
      setItemPool(initialItems.slice(2));
      
      setGameState({
        showResult: false,
        selectedItem: null,
        isAnimatingOut: false,
        isAnimatingIn: false,
        lastGuessCorrect: false,
        isGameOver: false
      });
    } catch (err) {
      console.error('Failed to restart game:', err);
      const fallbackPair = [...sampleData]
        .sort(() => Math.random() - 0.5)
        .slice(0, 2)
        .map(item => ({ ...item, isNew: true }));
      setCurrentPair(fallbackPair);
      setIsUsingSampleData(true);
    } finally {
      setIsLoading(false);
    }
  };

  const getWinnerLoserStatus = (item) => {
    const { showResult, selectedItem } = gameState;
    if (!showResult || !selectedItem || item !== selectedItem) {
      return { isWinner: false, isLoser: false };
    }

    const otherItem = currentPair.find(i => i !== item);
    const isWinner = item.year <= otherItem.year;
    return { isWinner, isLoser: !isWinner };
  };

  return {
    score,
    highScore,
    currentPair,
    showGameOver,
    gameState,
    isLoading,
    isUsingSampleData,
    handleGuess,
    handlePlayAgain,
    getWinnerLoserStatus
  };
};