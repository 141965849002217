export const handleShare = async (score, highScore) => {
    const text = `🎮 Higher or Lower?\nI scored ${score}!\n🏆 My best: ${highScore}\n\nThink you can beat me?\nPlay here: https://higherorlower.example.com`;
    
    try {
      if (navigator.share) {
        await navigator.share({
          title: 'Higher or Lower?',
          text: text,
          url: 'https://higherorlower.example.com'
        });
        return { success: true };
      } else {
        await navigator.clipboard.writeText(text);
        return { success: true, copied: true };
      }
    } catch (err) {
      return { success: false, error: err.message };
    }
  };