// src/ad/BannerAd.jsx
import React, { useEffect } from 'react';

export function BannerAd() {
    useEffect(() => {
        try {
            if (window.adsbygoogle) {
                (window.adsbygoogle = window.adsbygoogle || []).push({});
            }
        } catch (error) {
            console.log('Ad loading error:', error);
        }
    }, []);

    return (
        <div className="w-full flex justify-center">
            <div className="w-full h-[100px] bg-gray-50 rounded-lg overflow-hidden">
                <ins className="adsbygoogle"
                    style={{ display: 'block' }}
                    data-ad-client="YOUR-AD-CLIENT"
                    data-ad-slot="YOUR-AD-SLOT"
                    data-ad-format="auto"
                    data-full-width-responsive="true">
                </ins>
            </div>
        </div>
    );
}