import { useState } from 'react';

export function GameCard({ 
  item, 
  index, 
  showResult, 
  isWinner, 
  isLoser, 
  onSelect, 
  showYear ,
    bgColor
}) {
  const isLeftCard = index === 0;
  const [isAnimating, setIsAnimating] = useState(false);

  const handleClick = async () => {
    if (showResult || isAnimating) return;
    setIsAnimating(true);
    onSelect(item);
    
    await new Promise(resolve => setTimeout(resolve, 1000));
    setIsAnimating(false);
  };

  return (
<button
  onClick={handleClick}
  className={`
    group relative overflow-hidden
    ${isLeftCard ? 'md:transform md:-rotate-1' : 'md:transform md:rotate-1'}
    h-[280px] md:h-[600px]
    w-full
    rounded-2xl
    flex flex-col items-center justify-center gap-2 sm:gap-4
    transition-all duration-300
    ${showResult 
      ? isWinner
        ? 'border-4 border-green-400'
        : isLoser
          ? 'border-4 border-red-500'
          : 'border-2 border-gray-100'
      : 'hover:bg-white/10 border-2 border-gray-100'}
    shadow-lg hover:shadow-xl
    cursor-pointer
    disabled:cursor-not-allowed
    ${isLoser ? 'animate-shake' : ''}
  `}
  style={{ backgroundColor: bgColor }}
  disabled={showResult || isAnimating}
>
      <div className={`
        text-6xl sm:text-7xl md:text-8xl 
        transition-transform duration-300 
        group-hover:scale-110 
        drop-shadow-lg
        ${isWinner ? 'animate-bounce-small' : ''}
      `}>
        {item.emoji}
      </div>
      <div className={`
        text-lg sm:text-xl md:text-2xl
        font-medium 
        text-gray-800 
        mt-2 sm:mt-4
        bg-white/50 
        px-4 sm:px-6
        py-1.5 sm:py-2
        rounded-full 
        backdrop-blur-sm
        ${isWinner ? 'animate-pulse' : ''}
      `}>
        {item.name}
      </div>
      {(showYear) && (
        <div className={`
          text-base sm:text-lg md:text-xl
          font-bold text-gray-800 
          bg-white/70 
          px-4 sm:px-6 
          py-1.5 sm:py-2
          rounded-full 
          mt-2 sm:mt-4
          backdrop-blur-sm
          ${isLoser ? 'animate-shake' : ''}
        `}>
          {item.year}
        </div>
      )}
      {isWinner && (
        <div className="absolute inset-0 animate-success-flash pointer-events-none" />
      )}
      {isLoser && (
        <div className="absolute inset-0 animate-error-flash pointer-events-none" />
      )}
      <div className="absolute inset-0 pointer-events-none bg-gradient-to-br from-white/0 to-white/20 opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
    </button>
  );
}