import React from 'react';
import { RollingEmoji } from './RollingEmoji';

export function EmojiDisplay({ finalEmojis }) {
    return (
        <span className="inline-flex overflow-hidden">
            <RollingEmoji finalEmoji={finalEmojis[0]} stopDelay={0} />
            <RollingEmoji finalEmoji={finalEmojis[1]} stopDelay={400} />
            <RollingEmoji finalEmoji={finalEmojis[2]} stopDelay={800} />
        </span>
    );
}