import React, { useState } from 'react';

export function ColorModal({ colors }) {
    const [showModal, setShowModal] = useState(false);

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text);
    };

    return (
        <>
            <button
                onClick={() => setShowModal(true)}
                className="group flex items-center gap-2 px-4 py-2 bg-white/90 hover:bg-white rounded-full text-sm font-medium transition-all duration-300 hover:scale-105 hover:shadow-lg"
                style={{ color: '#1a1a1a' }}
            >
                <span className="inline-block transform group-hover:scale-110 transition-transform duration-300">
                    🎨
                </span>
                Like these colors?
            </button>

            {showModal && (
                <div 
                    className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50"
                    onClick={() => setShowModal(false)}
                >
                    <div 
                        className="bg-white rounded-2xl p-6 max-w-md w-full mx-4 shadow-xl"
                        onClick={e => e.stopPropagation()}
                    >
                        <h3 className="text-lg font-bold mb-4">Current Color Palette</h3>
                        
                        {Object.entries(colors).map(([name, color]) => (
                            <div key={name} className="mb-3 last:mb-0">
                                <div className="flex items-center justify-between mb-1">
                                    <span className="text-sm font-medium capitalize">{name}:</span>
                                    <div className="flex items-center gap-2">
                                        <div 
                                            className="w-6 h-6 rounded-full border border-gray-200" 
                                            style={{ backgroundColor: color }}
                                        />
                                        <code className="text-sm bg-gray-100 px-2 py-1 rounded">
                                            {color}
                                        </code>
                                        <button
                                            onClick={() => copyToClipboard(color)}
                                            className="text-blue-500 hover:text-blue-700 text-sm px-2 py-1 rounded hover:bg-blue-50 transition-colors"
                                        >
                                            Copy
                                        </button>
                                    </div>
                                </div>
                            </div>
                        ))}
                        
                        <button
                            className="mt-4 w-full bg-gray-100 hover:bg-gray-200 text-gray-800 py-2 rounded-lg transition-colors"
                            onClick={() => setShowModal(false)}
                        >
                            Close
                        </button>
                    </div>
                </div>
            )}
        </>
    );
}