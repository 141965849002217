import React, { useState, useEffect } from 'react';

export const emojis = [
    // Creation/Making themed
    "🛠️", "⚒️", "🔨", "⚡", "💡", "⚙️", "🔧", "📐", "✂️", "🧵",
    
    // Positive emotions/effort
    "❤️", "💖", "✨", "💫", "🔥", "💪", "🤘", "🫶", "🙌", "💯",
    
    // Materials/Elements
    "🧊", "🌊", "🔮", "💎", "🪄", "⭐", "🌟", "🎨", "🖌️", "🎯",
    
    // Tech/Coding
    "💻", "⌨️", "👨‍💻", "🤖", "💾", "📱", "🚀", "💡", "⚡", "💫",
    
    // Food/Drinks (for "cooked with" vibe)
    "☕", "🧋", "🫖", "🍵", "🧃", "🥤", "🧉", "🍯", "🧂", "🌶️",
    
    // Magic/Special
    "✨", "💫", "🪄", "🔮", "🎭", "🎪", "🎯", "🎨", "🎼", "🎵",
    
    // Fun states of mind
    "🤪", "🥳", "🤓", "😎", "🧐", "🤯", "🤠", "😤", "😌", "🫡",
    
    // Tools/Skills
    "🎯", "🎨", "🎮", "🎲", "🎭", "🎪", "🎼", "🎵", "🎷", "🎸",
    
    // Power/Energy
    "⚡", "💥", "🔥", "⭐", "💫", "✨", "💪", "🦾", "🧠", "💡",
    
    // Unique/Special touches
    "🦄", "🐉", "🌈", "🍀", "🎪", "🎨", "🎭", "🌟", "🌠", "🌌",
    
    // Science/Innovation
    "🧪", "⚗️", "🔬", "🧬", "🔭", "📡", "💊", "💉", "🧫", "🧲",
    
    // Building/Construction
    "🏗️", "🔧", "🔨", "⚒️", "🛠️", "⚙️", "🔩", "🧱", "🪛", "🪚",
    
    // Excellence/Quality
    "💯", "🏆", "🥇", "🎖️", "🏅", "👑", "💎", "🔱", "⚜️", "🎯",
    
    // Care/Attention
    "🤲", "👐", "🫂", "🤝", "🤌", "👌", "🫴", "🫵", "🤍", "💝"
];

export const RollingEmoji = ({ finalEmoji, stopDelay }) => {
    const [isRolling, setIsRolling] = useState(true);
    const [currentEmoji, setCurrentEmoji] = useState(emojis[0]);

    useEffect(() => {
        let interval;
        const duration = 2000 + stopDelay;
        let startTime = Date.now();

        if (isRolling) {
            interval = setInterval(() => {
                const elapsed = Date.now() - startTime;
                const intervalSpeed = Math.min(200, 100 + (elapsed / duration) * 300);
                
                setCurrentEmoji(emojis[Math.floor(Math.random() * emojis.length)]);

                if (elapsed >= duration) {
                    clearInterval(interval);
                    setCurrentEmoji(finalEmoji);
                    setIsRolling(false);
                }
            }, 100);
        }

        return () => clearInterval(interval);
    }, [isRolling, finalEmoji, stopDelay]);

    return (
        <span 
            className="inline-block w-[1.5em] text-center"
            style={{
                animation: isRolling ? `slide-up 100ms linear infinite` : 'none'
            }}
        >
            {currentEmoji}
        </span>
    );
};
