export function KofiButton() {
    return (
        <a
            href="https://ko-fi.com/harrygunn"
            target="_blank"
            rel="noopener noreferrer"
            className="group flex items-center gap-2 px-4 py-2 bg-white/90 hover:bg-white rounded-full text-sm font-medium transition-all duration-300 hover:scale-105 hover:shadow-lg"
            style={{ color: '#1a1a1a' }}
        >
            <span className="inline-block transform group-hover:scale-110 transition-transform duration-300">
                ☕
            </span>
            Buy me a coffee
        </a>
    );
}