export function ScoreBubble({ score, highScore, colour_one, colour_two }) {
  return (
    <div className="
      absolute 
      md:left-1/2 md:top-1/2 md:-translate-x-1/2 md:-translate-y-1/2
      left-4 top-4 
      z-10"
    >
      <div className="flex flex-col items-center gap-3">
        <div className="bg-white/95 backdrop-blur-md rounded-3xl px-8 py-4 
          shadow-[0_0_25px_rgba(0,0,0,0.2),0_0_0_2px_rgba(255,255,255,0.4)] 
          border-2 border-white/50 
          animate-float"
        >
          <div 
            className="text-5xl font-black bg-clip-text text-transparent animate-gradient-x"
            style={{
              backgroundImage: `linear-gradient(to right, ${colour_one}, ${colour_two}, ${colour_one})`,
              backgroundSize: '200% auto'
            }}
          >
            {score}
          </div>
        </div>
        <div className="text-base font-bold text-white 
          bg-black/40 backdrop-blur-md 
          rounded-full px-4 py-1.5
          shadow-[0_4px_12px_rgba(0,0,0,0.1)]
          border border-white/10"
        >
          Best: {highScore}
        </div>
      </div>
    </div>
  );
}