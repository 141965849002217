// src/share/ShareButton.jsx
import { useState } from 'react';

export const ShareButton = ({ score }) => {
  const [showOptions, setShowOptions] = useState(false);
  const [toast, setToast] = useState('');

  const shareText = `🎯 I scored ${score} on Which Came First!\n🤔 Can you beat my score?\nwhichcamefirst.app`;
  const encodedText = encodeURIComponent(shareText);

  const copyToClipboard = async () => {
    await navigator.clipboard.writeText(shareText);
    setToast('Copied');
    setTimeout(() => setToast(''), 2000);
    setShowOptions(false);
  };

  const shareLinks = {
    twitter: `https://twitter.com/intent/tweet?text=${encodedText}`,
    facebook: `https://www.facebook.com/sharer/sharer.php?u=game.com&quote=${encodedText}`,
    native: async () => {
      try {
        if (navigator.share) {
          await navigator.share({ text: shareText });
        } else {
          await copyToClipboard();
        }
      } catch {
        await copyToClipboard();
      }
      setShowOptions(false);
    }
  };

  return (
    <div className="relative flex justify-center w-full">
      <button
        onClick={() => setShowOptions(!showOptions)}
        className="flex items-center justify-center gap-2 px-4 py-2 rounded-lg 
                   bg-gray-100 hover:bg-gray-200 transition-colors w-full"
      >
        <svg className="w-5 h-5" viewBox="0 0 24 24" fill="none" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                d="M8.684 13.342C8.886 12.938 9 12.482 9 12c0-.482-.114-.938-.316-1.342m0 2.684a3 3 0 110-2.684m0 2.684l6.632 3.316m-6.632-6l6.632-3.316m0 0a3 3 0 105.367-2.684 3 3 0 00-5.367 2.684zm0 9.316a3 3 0 105.368 2.684 3 3 0 00-5.368-2.684z" />
        </svg>
        {toast || 'Share'}
      </button>

      {showOptions && (
        <div className="absolute bottom-full mb-2 bg-white rounded-lg shadow-lg p-2 flex flex-col gap-2 min-w-[150px]">
          <a
            href={shareLinks.twitter}
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center gap-2 px-3 py-2 hover:bg-gray-100 rounded"
            onClick={() => setShowOptions(false)}
          >
            Twitter
          </a>
          <a
            href={shareLinks.facebook}
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center gap-2 px-3 py-2 hover:bg-gray-100 rounded"
            onClick={() => setShowOptions(false)}
          >
            Facebook
          </a>
          <button
            onClick={shareLinks.native}
            className="flex items-center gap-2 px-3 py-2 hover:bg-gray-100 rounded text-left"
          >
            More Options
          </button>
          <button
            onClick={copyToClipboard}
            className="flex items-center gap-2 px-3 py-2 hover:bg-gray-100 rounded text-left"
          >
            Copy to Clipboard
          </button>
        </div>
      )}
    </div>
  );
};