import { createClient } from '@supabase/supabase-js';

const supabaseUrl = process.env.REACT_APP_NEXT_PUBLIC_SUPABASE_URL?.replace(/"/g, '');
const supabaseAnonKey = process.env.REACT_APP_NEXT_PUBLIC_SUPABASE_ANON_KEY?.replace(/"/g, '');

const TABLE_NAME = 'items';

export const supabase = createClient(supabaseUrl, supabaseAnonKey);

export const SupabaseRepository = {
  async getAllItems() {
    try {
      const { data, error } = await supabase
        .from(TABLE_NAME)
        .select('*');
      
      if (error) throw error;
      return data;
    } catch (error) {
      console.error('Error in getAllItems:', error);
      return [];
    }
  },
  
  async getRandomItems(count = 2, excludeId = null) {
    try {
      let query = supabase
        .from(TABLE_NAME)
        .select('*');
      
      if (excludeId) {
        query = query.neq('id', excludeId);
      }
      
      const { data, error } = await query;
      
      if (error) throw error;
      return data.sort(() => Math.random() - 0.5).slice(0, count);
    } catch (error) {
      console.error('Error in getRandomItems:', error);
      return [];
    }
  }
};