export const sampleData = [
    { id: 1, name: "Baseball", year: 1845, emoji: "⚾" },
    { id: 2, name: "Bicycle", year: 1817, emoji: "🚲" },
    // { id: 3, name: "Tea Bags", year: 1908, emoji: "☕" },
    // { id: 4, name: "Bubble Gum", year: 1928, emoji: "🫧" },
    // { id: 5, name: "Crossword Puzzle", year: 1913, emoji: "📝" },
    // { id: 6, name: "Frisbee", year: 1948, emoji: "🥏" },
    // { id: 7, name: "Coca-Cola", year: 1886, emoji: "🥤" },
    // { id: 8, name: "Ice Cream Cone", year: 1904, emoji: "🍦" },
    // { id: 9, name: "Band-Aid", year: 1920, emoji: "🩹" },
    // { id: 10, name: "Potato Chips", year: 1853, emoji: "🥔" },
    // { id: 11, name: "Pizza (Margherita)", year: 1889, emoji: "🍕" },
    // { id: 12, name: "Light Bulb", year: 1879, emoji: "💡" },
    // { id: 13, name: "Television", year: 1927, emoji: "📺" },
    // { id: 14, name: "Blue Jeans", year: 1873, emoji: "👖" },
    // { id: 15, name: "Teddy Bear", year: 1902, emoji: "🧸" },
    // { id: 16, name: "Paper Clip", year: 1899, emoji: "📎" },
    // { id: 17, name: "Instant Coffee", year: 1901, emoji: "☕" },
    // { id: 18, name: "Safety Pin", year: 1849, emoji: "🧷" },
    // { id: 19, name: "Monopoly Game", year: 1935, emoji: "🎲" },
    // { id: 20, name: "Rubber Band", year: 1845, emoji: "💫" },
    // { id: 21, name: "Typewriter", year: 1868, emoji: "⌨️" },
    // { id: 22, name: "Telephone", year: 1876, emoji: "☎️" },
    // { id: 23, name: "X-Ray", year: 1895, emoji: "🩻" },
    // { id: 24, name: "Aspirin", year: 1899, emoji: "💊" },
    // { id: 25, name: "Zeppelin", year: 1900, emoji: "🎈" },
    // { id: 26, name: "Air Conditioning", year: 1902, emoji: "❄️" },
    // { id: 27, name: "Airplane", year: 1903, emoji: "✈️" },
    // { id: 28, name: "Cornflakes", year: 1894, emoji: "🥣" },
    // { id: 29, name: "Zipper", year: 1917, emoji: "🤐" },
    // { id: 30, name: "Pop-Up Toaster", year: 1919, emoji: "🍞" },
    // { id: 31, name: "Traffic Signal", year: 1923, emoji: "🚦" },
    // { id: 32, name: "Penicillin", year: 1928, emoji: "🔬" },
    // { id: 33, name: "Chocolate Chip Cookie", year: 1938, emoji: "🍪" },
    // { id: 34, name: "Instant Camera", year: 1948, emoji: "📸" },
    // { id: 35, name: "Credit Card", year: 1950, emoji: "💳" },
    // { id: 36, name: "Velcro", year: 1955, emoji: "🔗" },
    // { id: 37, name: "Lego", year: 1958, emoji: "🧱" },
    // { id: 38, name: "Microwave Oven", year: 1946, emoji: "📠" },
    // { id: 39, name: "Internet", year: 1969, emoji: "🌐" },
    // { id: 40, name: "Post-it Notes", year: 1974, emoji: "📝" },
    // { id: 41, name: "Walkman", year: 1979, emoji: "🎧" },
    // { id: 42, name: "CD Player", year: 1982, emoji: "💿" },
    // { id: 43, name: "Microsoft Windows", year: 1985, emoji: "🪟" },
    // { id: 44, name: "World Wide Web", year: 1989, emoji: "🕸️" },
    // { id: 45, name: "Text Message", year: 1992, emoji: "📱" },
    // { id: 46, name: "PlayStation", year: 1994, emoji: "🎮" },
    // { id: 47, name: "Google Search", year: 1998, emoji: "🔍" },
    // { id: 48, name: "iPod", year: 2001, emoji: "🎵" },
    // { id: 49, name: "Facebook", year: 2004, emoji: "👥" },
    // { id: 50, name: "YouTube", year: 2005, emoji: "▶️" },
    // { id: 51, name: "iPhone", year: 2007, emoji: "📱" },
    // { id: 52, name: "Instagram", year: 2010, emoji: "📷" },
    // { id: 53, name: "TikTok", year: 2016, emoji: "🎵" },
    // { id: 54, name: "Phonograph", year: 1877, emoji: "📀" },
    // { id: 55, name: "Snowboard", year: 1965, emoji: "🏂" },
    // { id: 56, name: "Digital Camera", year: 1975, emoji: "📸" },
    // { id: 57, name: "Game Boy", year: 1989, emoji: "🎮" },
    // { id: 58, name: "DVD Player", year: 1996, emoji: "📀" },
    // { id: 59, name: "Bluetooth", year: 1994, emoji: "📶" },
    // { id: 60, name: "WiFi", year: 1997, emoji: "📶" },

    // { id: 61, name: "Rubik's Cube", year: 1974, emoji: "🪀" },
    // { id: 62, name: "Wheel", year: -3500, emoji: "⚙️" },
    // { id: 63, name: "Compass", year: -1040, emoji: "🧭" },
    // { id: 64, name: "Paper Money", year: 618, emoji: "💵" },
    // { id: 65, name: "Glasses", year: 1286, emoji: "👓" },
    // { id: 66, name: "Printing Press", year: 1440, emoji: "📖" },
    // { id: 67, name: "Microscope", year: 1590, emoji: "🔬" },
    // { id: 68, name: "Telescope", year: 1608, emoji: "🔭" },
    // { id: 69, name: "Steam Engine", year: 1712, emoji: "🚂" },
    // { id: 70, name: "Hot Air Balloon", year: 1783, emoji: "🎈" },
    // { id: 71, name: "Stethoscope", year: 1816, emoji: "⚕️" },
    // { id: 72, name: "Photography", year: 1826, emoji: "📷" },
    // { id: 73, name: "Morse Code", year: 1844, emoji: "📟" },
    // { id: 74, name: "Dynamite", year: 1867, emoji: "💥" },
    // { id: 75, name: "Contact Lenses", year: 1888, emoji: "👁️" },
    // { id: 76, name: "Radio", year: 1895, emoji: "📻" },
    // { id: 77, name: "Escalator", year: 1896, emoji: "⤴️" },
    // { id: 78, name: "Safety Razor", year: 1901, emoji: "🪒" },
    // { id: 79, name: "Helicopter", year: 1907, emoji: "🚁" },
    // { id: 80, name: "Bra", year: 1914, emoji: "👙" },
    // { id: 81, name: "Super Glue", year: 1942, emoji: "💧" },
    // { id: 82, name: "Transistor", year: 1947, emoji: "🔌" },
    // { id: 83, name: "Credit Card", year: 1950, emoji: "💳" },
    // { id: 84, name: "Barcode", year: 1952, emoji: "📊" },
    // { id: 85, name: "Solar Cell", year: 1954, emoji: "☀️" },
    // { id: 86, name: "Artificial Heart", year: 1957, emoji: "❤️" },
    // { id: 87, name: "Skateboard", year: 1958, emoji: "🛹" },
    // { id: 88, name: "Computer Mouse", year: 1964, emoji: "🖱️" },
    // { id: 89, name: "ATM", year: 1967, emoji: "🏧" },
    // { id: 90, name: "MRI Scanner", year: 1977, emoji: "🏥" },
    // { id: 91, name: "Space Shuttle", year: 1981, emoji: "🚀" },
    // { id: 92, name: "DNA Fingerprinting", year: 1984, emoji: "🧬" },
    // { id: 93, name: "Linux", year: 1991, emoji: "🐧" },
    // { id: 94, name: "Amazon", year: 1994, emoji: "📦" },
    // { id: 95, name: "Netflix", year: 1997, emoji: "🎬" },
    // { id: 96, name: "Bluetooth", year: 1999, emoji: "📶" },
    // { id: 97, name: "Wikipedia", year: 2001, emoji: "📚" },
    // { id: 98, name: "Tesla Motors", year: 2003, emoji: "🚗" },
    // { id: 99, name: "Twitter", year: 2006, emoji: "🐦" },
    // { id: 100, name: "Uber", year: 2009, emoji: "🚕" },
    // { id: 101, name: "iPad", year: 2010, emoji: "📱" },
    // { id: 102, name: "Bitcoin", year: 2009, emoji: "💰" },
    // { id: 103, name: "Snapchat", year: 2011, emoji: "👻" },
    // { id: 104, name: "Disney+", year: 2019, emoji: "🎥" },
    // { id: 105, name: "Virtual Reality", year: 1968, emoji: "🥽" },
    // { id: 106, name: "Post Office", year: 1660, emoji: "📬" },
    // { id: 107, name: "Sandwich", year: 1762, emoji: "🥪" },
    // { id: 108, name: "Vacuum Cleaner", year: 1901, emoji: "🧹" },
    // { id: 109, name: "Chewing Gum", year: 1848, emoji: "🌿" },
    // { id: 110, name: "Modern Olympics", year: 1896, emoji: "🏅" },
    // { id: 111, name: "World Cup", year: 1930, emoji: "⚽" },
    // { id: 112, name: "NBA", year: 1946, emoji: "🏀" },
    // { id: 113, name: "NFL", year: 1920, emoji: "🏈" },
    // { id: 114, name: "NHL", year: 1917, emoji: "🏒" },
    // { id: 115, name: "USB Port", year: 1996, emoji: "🔌" },
    // { id: 116, name: "mp3 Format", year: 1995, emoji: "🎵" },
    // { id: 117, name: "Gmail", year: 2004, emoji: "📧" },
    // { id: 118, name: "Spotify", year: 2008, emoji: "🎵" },
    // { id: 119, name: "WhatsApp", year: 2009, emoji: "💬" },
    // { id: 120, name: "Minecraft", year: 2011, emoji: "⛏️" }
];
