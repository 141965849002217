// src/GameOverModal.jsx
import React from 'react';
import { ShareButton } from '../share/ShareButton';
import { BannerAd } from '../ad/BannerAd';

export function GameOverModal({ score, highScore, onShare, onPlayAgain }) {
    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white rounded-lg p-6 max-w-sm w-full mx-4 transform transition-all">
                <h3 className="text-2l font-bold text-center mb-4">whichcamefirst</h3>
                <h2 className="text-2xl font-bold text-center mb-4">Game Over!</h2>
                <div className="space-y-3 mb-6">
                    <div className="flex justify-between">
                        <span className="font-medium">Score:</span>
                        <span>{score}</span>
                    </div>
                    <div className="flex justify-between">
                        <span className="font-medium">Highest Score:</span>
                        <span>{highScore}</span>
                    </div>
                </div>
                <div className="space-y-3 mb-6">
                    <ShareButton score={score} highScore={highScore} />

                    <button
                        onClick={onPlayAgain}
                        className="w-full py-2 px-4 bg-gray-200 text-gray-800 rounded-lg hover:bg-gray-300 transition-colors"
                    >
                        Play Again
                    </button>
                </div>

                <BannerAd />
            </div>
        </div>
    );
}